import { AwsRegionCodes, AwsRegions } from "../constants";

export const getBaseUrl = () => {
	const currentUrl = window.location.href;
	const baseUrl = new URL(currentUrl).origin;

	return baseUrl;
};

export const getRegionName = (code: AwsRegionCodes) => {
	const region = AwsRegions.find((r) => r.code === code);
	return region ? region.name : "Unknown region";
};
