export enum CanaryStates {
	CREATING = "CREATING",
	READY = "READY",
	STARTING = "STARTING",
	RUNNING = "RUNNING",
	UPDATING = "UPDATING",
	STOPPING = "STOPPING",
	STOPPED = "STOPPED",
	ERROR = "ERROR",
	DELETING = "DELETING",
}

export enum CanaryStateReasonCodes {
	INVALID_PERMISSIONS = "INVALID_PERMISSIONS",
	CREATE_PENDING = "CREATE_PENDING",
	CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
	CREATE_FAILED = "CREATE_FAILED",
	UPDATE_PENDING = "UPDATE_PENDING",
	UPDATE_IN_PROGRESS = "UPDATE_IN_PROGRESS",
	UPDATE_COMPLETE = "UPDATE_COMPLETE",
	ROLLBACK_COMPLETE = "ROLLBACK_COMPLETE",
	ROLLBACK_FAILED = "ROLLBACK_FAILED",
	DELETE_IN_PROGRESS = "DELETE_IN_PROGRESS",
	DELETE_FAILED = "DELETE_FAILED",
	SYNC_DELETE_IN_PROGRESS = "SYNC_DELETE_IN_PROGRESS",
}

export enum CanaryRunStates {
	RUNNING = "RUNNING",
	PASSED = "PASSED",
	FAILED = "FAILED",
}

export enum CanaryRunStateReasonCodes {
	CANARY_FAILURE = "CANARY_FAILURE",
	EXECUTION_FAILURE = "EXECUTION_FAILURE",
}

export interface CanaryStatus {
	state?: CanaryStates;
	stateReason?: string;
	stateReasonCode?: CanaryStateReasonCodes;
	updated?: number;
}

export interface CanaryRunStatus {
	state?: CanaryRunStates;
	stateReason?: string;
	stateReasonCode?: CanaryRunStateReasonCodes;
	updated?: number;
}

export interface Tag {
	key: string;
	value: string;
}
