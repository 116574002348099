import { CanaryListItem } from "../../../api";
import { SortableHeaders } from "../../../constants";
import { CanariesListFilters } from "../../../types";

export const filterCanaries = (
	canaries: CanaryListItem[],
	filters: CanariesListFilters
): CanaryListItem[] => {
	const { searchValue, tags } = filters;
	return canaries.filter((canary) => {
		const matchesSearch = searchValue
			? canary.name.includes(searchValue)
			: true;

		const matchesTags = tags.every((tag) => {
			return canary.tags.some(
				(canaryTag) =>
					canaryTag.key.toLowerCase() === tag.key.toLowerCase() &&
					canaryTag.value.toLowerCase() === tag.value.toLowerCase()
			);
		});

		return matchesSearch && matchesTags;
	});
};

export const compareCanaries = (
	a: CanaryListItem,
	b: CanaryListItem,
	orderBy: SortableHeaders,
	comparator: (a?: number | string, b?: number | string) => number
) => {
	if (orderBy === "name") {
		return comparator(a.name, b.name);
	}
	if (orderBy === "endpoint") {
		return comparator(a.pingInterval, b.pingInterval);
	}
	return 0;
};
