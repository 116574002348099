import {
	Box,
	Chip,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { AlarmConfiguration, Canary } from "../../api";

export type CanaryConfigurationProps = {
	canary: Canary;
	alarmConfigurations: AlarmConfiguration[];
	moreAlarmConfigurationsAvailable: boolean;
	handleGetNextAlarmConfigurations: () => Promise<void>;
};

export const CanaryConfiguration = ({
	canary,
	alarmConfigurations,
	moreAlarmConfigurationsAvailable,
	handleGetNextAlarmConfigurations,
}: CanaryConfigurationProps) => {
	const [page, setPage] = useState(0);
	const rowsPerPage = 10;

	useEffect(() => {
		if (alarmConfigurations.length <= rowsPerPage) {
			setPage(0);
		}
	}, [alarmConfigurations]);

	const itemsCount = moreAlarmConfigurationsAvailable
		? -1
		: alarmConfigurations.length;

	const handleChangePage = async (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		if (
			newPage > page &&
			alarmConfigurations.length <= newPage * rowsPerPage &&
			moreAlarmConfigurationsAvailable
		) {
			await handleGetNextAlarmConfigurations();
		}
		setPage(newPage);
	};

	const visibleRows = useMemo(
		() =>
			alarmConfigurations.slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[alarmConfigurations, page, rowsPerPage]
	);

	return (
		<Box>
			<TableContainer component={Paper} elevation={0}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell colSpan={4}>
								<Typography variant="h6">Canary Details</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								<Box>
									<Typography variant="body2" color="GrayText">
										Name
									</Typography>
									<Typography variant="body2">{canary.name}</Typography>
								</Box>
							</TableCell>
							<TableCell>
								<Box>
									<Typography variant="body2" color="GrayText">
										Endpoint
									</Typography>
									<Typography variant="body2">{canary.endpoint}</Typography>
								</Box>
							</TableCell>
							<TableCell>
								<Box>
									<Typography variant="body2" color="GrayText">
										Ping Interval
									</Typography>
									<Typography variant="body2">{`${canary.pingInterval} minutes`}</Typography>
								</Box>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={3}>
								<Box>
									<Typography variant="body2" color="GrayText">
										Locations
									</Typography>
									{canary.locations.map((l) => (
										<Chip
											key={l}
											label={l}
											variant="filled"
											sx={{ marginRight: 0.2 }}
										/>
									))}
								</Box>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={3}>
								<Box>
									<Typography variant="body2" color="GrayText">
										Tags
									</Typography>
									{canary.tags.map(({ key, value }) => (
										<Chip
											key={key}
											label={`${key}: ${value}`}
											variant="filled"
											sx={{ marginRight: 0.2 }}
										/>
									))}
								</Box>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<TableContainer component={Paper} elevation={0} sx={{ mt: 3 }}>
				<Typography variant="h6" p={2}>
					Alarms ({itemsCount})
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Alarm Name</TableCell>
							<TableCell>Metric Name</TableCell>
							<TableCell>SNS Topics</TableCell>
							<TableCell>Threshold</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{visibleRows.map((x) => (
							<TableRow key={x.alarmName}>
								<TableCell>{x.alarmName}</TableCell>
								<TableCell>{x.metricName}</TableCell>
								<TableCell>{x.snsTopic}</TableCell>
								<TableCell>{x.threshold}</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={5}
								count={itemsCount}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={[]}
								page={page}
								onPageChange={handleChangePage}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
};
