import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Box,
	Button,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import { useState } from "react";

import { CanaryRunStates, CanaryStates } from "../../api";
import { AwsRegionCodes } from "../../constants";
import { capitalizeFirstLetter } from "../../utils";
import { CanaryRunStatus as CanaryRunStatusComponent } from "./CanaryRunStatus";

type Statuses = {
	region: AwsRegionCodes;
	status: {
		state: CanaryStates | CanaryRunStates | null;
		updated: string | null;
	};
}[];

type StatusesType = "canary" | "run";

export interface StatusesDropdownProps {
	id: string;
	type: StatusesType;
	label: string;
	statuses: Statuses;
}

export const StatusesDropdown = ({
	id,
	type,
	label,
	statuses,
}: StatusesDropdownProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				id={`${id}-btn`}
				onClick={handleClick}
				color="inherit"
				endIcon={<ArrowDropDownIcon />}
			>
				<Typography variant="body2">{label}</Typography>
			</Button>
			<Menu
				id={`${id}-menu`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": `${id}-btn`,
					style: { padding: 0 },
				}}
			>
				{statuses.map((run, index) => (
					<MenuItem key={index} dense={true} divider sx={{ paddingY: 1 }}>
						<Tooltip
							title={`Date: ${run?.status?.updated ?? "N/A"}`}
							placement="right"
						>
							<Box
								display={"flex"}
								justifyContent={"space-between"}
								width={"100%"}
								gap={2}
							>
								<span>{run.region}</span>
								{type === "run" ? (
									<CanaryRunStatusComponent
										status={run.status.state as CanaryRunStates}
									></CanaryRunStatusComponent>
								) : (
									<span>
										{run.status.state
											? capitalizeFirstLetter(run.status.state.toLowerCase())
											: "N/A"}
									</span>
								)}
							</Box>
						</Tooltip>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
