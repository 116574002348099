import dayjs from "dayjs";
import { useState } from "react";
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import { MultiRegionTooltip } from "./MultiRegionTooltip";
import { calcAxisTimestamps, customDotProps, lineColors } from "./utils";

type Props = {
	data: {
		location: string;
		data: {
			timestamp: number;
			value: number;
		}[];
	}[];
};

type LegendPayload = { name: string };

export const DurationChart = ({ data }: Props) => {
	const rangeInHours = 24;
	const [activeSeries, setActiveSeries] = useState<Array<string>>([]);

	const handleLegendClick = (payload: LegendPayload) => {
		const dataKey = payload.name;
		if (activeSeries.includes(dataKey)) {
			setActiveSeries(activeSeries.filter((el) => el !== dataKey));
		} else {
			setActiveSeries((prev) => [...prev, dataKey]);
		}
	};

	const { startTimestamp, endTimestamp, xAxisTicks } =
		calcAxisTimestamps(rangeInHours);

	return (
		<ResponsiveContainer height={200}>
			<LineChart width={730} height={200} margin={{ top: 15 }}>
				<CartesianGrid strokeDasharray="3 3" vertical={false} />
				<XAxis
					type="number"
					domain={[startTimestamp.valueOf(), endTimestamp.valueOf()]}
					dataKey="timestamp"
					ticks={xAxisTicks}
					tickFormatter={(v) => dayjs(v).format("H:mm")}
					style={{
						fontSize: "0.8rem",
					}}
					allowDataOverflow
					allowDuplicatedCategory={false}
				/>
				<YAxis
					tickCount={3}
					dataKey={"value"}
					style={{
						fontSize: "0.8rem",
					}}
				/>
				{data.map((regionalData, index) => (
					<Line
						key={index}
						data={regionalData.data}
						name={regionalData.location}
						dataKey={"value"}
						hide={activeSeries.includes(regionalData.location)}
						stroke={lineColors[index]}
						isAnimationActive={false}
						{...customDotProps(lineColors[index])}
					/>
				))}
				<Legend
					iconType="circle"
					iconSize={5}
					wrapperStyle={{ fontSize: "14px" }}
					onClick={({ payload }) =>
						handleLegendClick(payload as unknown as LegendPayload)
					}
				/>
				<Tooltip
					isAnimationActive={false}
					position={{ y: 30 }}
					content={<MultiRegionTooltip unit="ms" />}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};
