import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

import { SortableHeaders } from "../../../constants";
import { SortingOrder } from "../../../types";

interface HeadCell {
	id: string;
	label: string;
	sortable: boolean;
	sortKey?: SortableHeaders;
}

const headCells: HeadCell[] = [
	{
		id: "name",
		label: "Name",
		sortable: true,
		sortKey: SortableHeaders.NAME,
	},
	{
		id: "endpoint",
		label: "Endpoint",
		sortable: true,
		sortKey: SortableHeaders.ENDPOINT,
	},
	{
		id: "lastRun",
		label: "Last run status",
		sortable: false,
	},
	{
		id: "status",
		label: "State",
		sortable: false,
	},
	{
		id: "tags",
		label: "Tags",
		sortable: false,
	},
	{
		id: "actions",
		label: "Actions",
		sortable: false,
	},
];

export interface CanariesTableHeaderProps {
	order: SortingOrder;
	orderBy: string;
	createSortHandler: (property: SortableHeaders) => () => void;
}
export const CanariesTableHeader = ({
	order,
	orderBy,
	createSortHandler,
}: CanariesTableHeaderProps) => {
	return (
		<TableHead>
			<TableRow>
				{headCells.map(({ id, label, sortable, sortKey }) => (
					<TableCell key={id}>
						{sortable && sortKey ? (
							<TableSortLabel
								active={orderBy === id}
								direction={orderBy === id ? order : "asc"}
								onClick={createSortHandler(sortKey)}
							>
								{label}
							</TableSortLabel>
						) : (
							label
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};
