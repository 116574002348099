import { Container, Stack, Typography } from "@mui/material";
import { Button, Spinner } from "@unified-trials/arcane-ui-tool";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CanaryListItem } from "../../api";
import { DeletionConfirmationModal } from "../../components/DeletionConfirmationModal";
import { CanariesTable } from "../../components/canaries/list/CanariesTable";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";

export const ListCanaries = () => {
	const navigate = useNavigate();
	const { isLoading, startCanary, pauseCanary, listCanaries, deleteCanary } =
		useApi();
	const [selectedCanary, setSelectedCanary] = useState<string>("");
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
	const [canaries, setCanaries] = useState<CanaryListItem[]>([]);

	const fetchData = async () => {
		const { data: canariesResponse } = await listCanaries();

		if (canariesResponse?.data?.length) {
			setCanaries(canariesResponse.data);
		}
	};

	const handleStart = async (canaryName: string) => {
		const { error } = await startCanary(canaryName);
		if (!error) {
			await fetchData();
			showNotification("success", `Successfully began starting ${canaryName}`);
			// setTimeout(async () => {
			// 	await fetchData();
			// }, 5000);
		}
	};

	const handleStop = async (canaryName: string) => {
		const { error } = await pauseCanary(canaryName);
		if (!error) {
			await fetchData();
			showNotification("success", `Successfully began stopping ${canaryName}`);
			// setTimeout(async () => {
			// 	await fetchData();
			// }, 5000);
		}
	};

	const handleDelete = async () => {
		setIsConfirmationOpen(false);
		if (selectedCanary) {
			const { error } = await deleteCanary(selectedCanary);
			if (!error) {
				await fetchData();
				showNotification(
					"success",
					`Successfully started deletion of ${selectedCanary}`
				);
			}
		}
	};

	const handleDeleteConfirmation = (canaryName: string) => {
		setSelectedCanary(canaryName);
		setIsConfirmationOpen(true);
	};

	const handleCreateClick = () => {
		navigate(`/canaries/create`);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Container sx={{ marginBottom: 2 }}>
			{isLoading && <Spinner fullscreen opacity={0.5} />}
			<Stack direction="row-reverse" spacing={2} my={2}>
				<Button variant="outlined" onClick={handleCreateClick}>
					<Typography>Create canary</Typography>
				</Button>
			</Stack>
			<CanariesTable
				canaries={canaries}
				onStart={handleStart}
				onStop={handleStop}
				onDelete={handleDeleteConfirmation}
			/>
			<DeletionConfirmationModal
				isOpen={isConfirmationOpen}
				setIsOpen={setIsConfirmationOpen}
				handleDelete={handleDelete}
				resource={selectedCanary}
			/>
		</Container>
	);
};
