import { zodResolver } from "@hookform/resolvers/zod";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Checkbox,
	Chip,
	Container,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { BackButton, Spinner } from "@unified-trials/arcane-ui-tool";
import React from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { CreateCanaryRequest } from "../../api";
import { AwsRegionCodes, AwsRegions } from "../../constants";
import { useApi } from "../../hooks";
import { getRegionName, showNotification } from "../../utils";

const CanarySchema = z.object({
	name: z
		.string()
		.min(1)
		.regex(
			/^[0-9a-z_-]+$/,
			"Invalid name. Only lowercase letters, numbers, underscores, and hyphens are allowed."
		)
		.max(21),
	endpoint: z.string().url(),
	pingInterval: z.coerce.number().positive().min(1).max(59),
	locations: z
		.array(z.nativeEnum(AwsRegionCodes))
		.min(1, "At least one region is required"),
	emailRecipients: z
		.array(z.object({ email: z.string().email() }))
		.min(1, "At least one recipient is required"),
	email: z.string().email().optional().or(z.literal("")),
	tags: z.array(z.object({ key: z.string().min(1), value: z.string().min(1) })),
	tlsWarningThreshold: z.coerce.number().positive().min(1),
	tlsErrorThreshold: z.coerce.number(),
});

type FormValues = z.infer<typeof CanarySchema>;

export const CreateCanary: React.FC = () => {
	const {
		handleSubmit,
		control,
		register,
		watch,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "all",
		resolver: zodResolver(CanarySchema),
		defaultValues: {
			name: "",
			endpoint: "",
			pingInterval: 5,
			locations: [AwsRegionCodes.EU_WEST_1],
			emailRecipients: [],
			email: "",
			tlsWarningThreshold: 30,
			tlsErrorThreshold: 0,
		},
	});

	const { createCanary, isLoading } = useApi();
	const navigate = useNavigate();

	const {
		fields: emailFields,
		append: appendEmail,
		remove: removeEmail,
	} = useFieldArray({
		control,
		name: "emailRecipients",
	});

	const {
		fields: tagFields,
		append: appendTag,
		remove: removeTag,
	} = useFieldArray({
		control,
		name: "tags",
	});

	const addTag = () => {
		appendTag({
			key: "",
			value: "",
		});
	};

	const addEmail = () => {
		const email = getValues("email");
		if (email) {
			appendEmail({
				email,
			});
			setValue("email", "");
		}
	};

	const handleFormSubmit: SubmitHandler<FormValues> = async (data) => {
		const requestData: CreateCanaryRequest = {
			name: data.name,
			endpoint: data.endpoint,
			pingInterval: data.pingInterval,
			locations: data.locations,
			alertConfig: {
				tlsWarningThreshold: data.tlsWarningThreshold,
				tlsErrorThreshold: data.tlsErrorThreshold,
				emailRecipients: data.emailRecipients.map((e) => e.email),
			},
			tags: data.tags,
		};
		const { error } = await createCanary(requestData);
		if (!error) {
			showNotification(
				"success",
				"Successfully started creation of new configuration!"
			);
			navigate("/canaries");
		}
	};

	return (
		<Container sx={{ marginBottom: 2 }}>
			{isLoading && <Spinner fullscreen opacity={0.5} />}
			<Stack direction="row" spacing={2} my={1} alignItems="center">
				<BackButton href="/canaries" />
				<Typography variant="h4" my={2}>
					Create Canary
				</Typography>
			</Stack>
			<form onSubmit={handleSubmit(handleFormSubmit)}>
				<Paper sx={{ p: 2 }} elevation={0}>
					<Stack direction="column" spacing={2} mb={1}>
						<TextField
							{...register("name")}
							size="small"
							sx={{ maxWidth: "500px" }}
							label="Name"
							error={!!errors.name}
							helperText={errors.name?.message}
							inputProps={{ "data-testid": "name-input" }}
						/>
						<TextField
							{...register("endpoint")}
							size="small"
							sx={{ maxWidth: "500px" }}
							label="Endpoint"
							error={!!errors.endpoint}
							helperText={errors.endpoint?.message}
							inputProps={{ "data-testid": "endpoint-input" }}
						/>
						<TextField
							{...register("pingInterval")}
							size="small"
							sx={{ maxWidth: "500px" }}
							type="number"
							inputProps={{ min: 1, "data-testid": "ping-interval-input" }}
							label="Ping Interval (in minutes)"
							error={!!errors.pingInterval}
							helperText={errors.pingInterval?.message}
						/>
						<FormControl sx={{ maxWidth: "500px" }} error={!!errors.locations}>
							<FormLabel>Locations (AWS regions)</FormLabel>
							<Select
								{...register("locations")}
								multiple
								size="small"
								value={watch("locations")}
								renderValue={(selected: AwsRegionCodes[]) =>
									selected.length > 3
										? `${selected.length} locations selected`
										: selected.map((s) => getRegionName(s)).join(", ")
								}
								error={!!errors.locations}
							>
								{AwsRegions.map((r) => (
									<MenuItem key={r.code} value={r.code}>
										<Checkbox checked={watch("locations").includes(r.code)} />
										<ListItemText primary={r.name} secondary={r.code} />
									</MenuItem>
								))}
							</Select>
							{errors.locations && (
								<FormHelperText>{errors.locations.message}</FormHelperText>
							)}
						</FormControl>
					</Stack>
				</Paper>

				<Paper sx={{ p: 2, my: 2 }} elevation={0}>
					<FormControl
						sx={{ maxWidth: "500px" }}
						error={!!errors.emailRecipients}
					>
						<FormLabel>Email Recipients</FormLabel>
						<Stack direction="row" sx={{ flexWrap: "wrap" }}>
							{emailFields.map((field, index) => (
								<Chip
									key={field.id}
									label={field.email}
									onDelete={() => removeEmail(index)}
									variant="outlined"
									color="primary"
									sx={{
										p: 0.5,
										m: 0.5,
										"& .MuiChip-label": {
											overflow: "visible",
										},
									}}
								/>
							))}
						</Stack>
					</FormControl>
					<Stack direction="row" alignItems="center" spacing={2} my={1}>
						<TextField
							{...register("email")}
							size="small"
							sx={{ maxWidth: "500px" }}
							fullWidth
							label="Email"
							error={!!errors.email || !!errors.emailRecipients}
							helperText={
								errors.email?.message ?? errors.emailRecipients?.message
							}
							inputProps={{ "data-testid": "email-recipient-input" }}
						/>
						<IconButton
							size="small"
							onClick={addEmail}
							disabled={!!errors.email || !watch("email")}
							color="primary"
							data-testid={"add-email-recipient"}
						>
							<AddIcon sx={{ cursor: "pointer" }} />
						</IconButton>
					</Stack>
				</Paper>
				<Paper sx={{ p: 2, my: 2 }} elevation={0}>
					<FormControl sx={{ maxWidth: "500px" }} error={!!errors.tags}>
						<FormLabel>Tags</FormLabel>
						{tagFields.map((field, index) => (
							<Stack
								key={field.id}
								direction="row"
								spacing={2}
								sx={{ mb: 2, width: "100%" }}
							>
								<TextField
									{...register(`tags.${index}.key`)}
									size="small"
									sx={{ maxWidth: "500px" }}
									fullWidth
									label="Tag Key"
									error={!!errors.tags?.[index]?.key}
									helperText={errors.tags?.[index]?.key?.message}
									inputProps={{ "data-testid": "tag-key-input" }}
								/>
								<TextField
									{...register(`tags.${index}.value`)}
									size="small"
									sx={{ maxWidth: "500px" }}
									fullWidth
									label="Tag Value"
									error={!!errors.tags?.[index]?.value}
									helperText={errors.tags?.[index]?.value?.message}
									inputProps={{ "data-testid": "tag-value-input" }}
								/>
								<IconButton onClick={() => removeTag(index)} color="primary">
									<DeleteIcon sx={{ cursor: "pointer" }} />
								</IconButton>
							</Stack>
						))}
					</FormControl>
					<Stack direction="row" alignItems="center" spacing={2} my={1}>
						<Button
							size="small"
							variant="outlined"
							onClick={addTag}
							color="primary"
							data-testid={"add-tag-button"}
						>
							Add new tag
						</Button>
					</Stack>
				</Paper>

				<Accordion elevation={0}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 2 }}>
						Alarms configuration
					</AccordionSummary>
					<AccordionDetails>
						<Stack direction="row" spacing={2} sx={{ width: "100%" }}>
							<TextField
								{...register("tlsWarningThreshold")}
								size="small"
								type="number"
								sx={{ maxWidth: "500px" }}
								fullWidth
								inputProps={{
									min: 1,
									"data-testid": "tls-warning-threshold-input",
								}}
								label="Warning Threshold"
								error={!!errors.tlsWarningThreshold}
								helperText={errors.tlsWarningThreshold?.message}
							/>
						</Stack>
					</AccordionDetails>
				</Accordion>

				<Stack direction="row" spacing={2} my={1} justifyContent={"center"}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						sx={{ color: "white " }}
					>
						Submit
					</Button>
				</Stack>
			</form>
		</Container>
	);
};
