import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import React, { useState } from "react";

import { HelpDocumentDialog } from "./HelpDocumentDialog";

export const HelpDocumentButton = () => {
	const { palette } = useTheme();
	const [isHelpDocumentOpen, setIsHelpDocumentOpen] = useState(false);
	return (
		<>
			<Tooltip title="Help">
				<IconButton
					sx={{
						borderRadius: 10,
						color: "primary.dark",
						justifyContent: "flex-start",
						textAlign: "left",
						border: "1px solid transparent",
						"&:hover": {
							backgroundColor: palette.primary.light,
						},
					}}
					size="large"
					onClick={() => setIsHelpDocumentOpen(true)}
				>
					<HelpOutlineIcon />
				</IconButton>
			</Tooltip>
			{isHelpDocumentOpen && (
				<HelpDocumentDialog
					isOpen={isHelpDocumentOpen}
					setIsOpen={setIsHelpDocumentOpen}
				/>
			)}
		</>
	);
};
