import { useState } from "react";

import { CanaryStates } from "../api";

export const useActionsButtonsState = (
	canaryStates: (CanaryStates | undefined)[]
) => {
	const [actionsDisabled, setActionsDisabled] = useState(false);

	const disableActions = () => {
		setActionsDisabled(true);
	};

	const inProgressStatuses = [
		CanaryStates.CREATING,
		CanaryStates.STARTING,
		CanaryStates.DELETING,
		CanaryStates.CREATING,
		CanaryStates.STOPPING,
		CanaryStates.UPDATING,
	];

	const { someInProgress, allRunning, shouldDisableStop } = canaryStates.reduce(
		(acc, state) => {
			if (state) {
				if (inProgressStatuses.includes(state)) {
					acc.someInProgress = true;
				}
				if (state !== CanaryStates.RUNNING) {
					acc.allRunning = false;
				}
				if (state !== CanaryStates.STOPPED) {
					acc.shouldDisableStop = false;
				}
			} else {
				acc.allRunning = false;
				acc.shouldDisableStop = false;
			}
			return acc;
		},
		{ someInProgress: false, allRunning: true, shouldDisableStop: true }
	);

	const startDisabled = actionsDisabled || someInProgress || allRunning;
	const stopDisabled = actionsDisabled || shouldDisableStop;
	const deleteDisabled =
		actionsDisabled ||
		canaryStates.some((state) => state === CanaryStates.DELETING);

	return { startDisabled, stopDisabled, deleteDisabled, disableActions };
};
