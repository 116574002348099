import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {
	Box,
	Chip,
	Divider,
	IconButton,
	TableCell,
	TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

import { CanaryListItem, CanaryRunStates, CanaryStates } from "../../../api";
import { useActionsButtonsState } from "../../../hooks";
import { StatusesDropdown } from "../StatusesDropdown";

export type CanariesTableRowProps = {
	canary: CanaryListItem;
	onStart: (canaryId: string) => Promise<void>;
	onStop: (canaryId: string) => Promise<void>;
	onDelete: (canaryId: string) => void;
};

export const CanariesTableRow = ({
	canary,
	onStart,
	onStop,
	onDelete,
}: CanariesTableRowProps) => {
	const canaryStates = canary.statuses
		.filter((s) => canary.locations.includes(s.region))
		.map((s) => s.status?.state);
	const { startDisabled, stopDisabled, deleteDisabled, disableActions } =
		useActionsButtonsState(canaryStates);

	const stopHandler = async () => {
		disableActions();
		await onStop(canary.name);
	};

	const startHandler = async () => {
		disableActions();
		await onStart(canary.name);
	};

	const deleteHandler = async () => {
		onDelete(canary.name);
	};
	const passedCount = canary.lastRunStatuses.filter(
		(item) => item.status.state === CanaryRunStates.PASSED
	).length;

	const runningCount = canary.statuses.filter(
		(item) => item.status.state === CanaryStates.RUNNING
	).length;

	const lastRuns = canary.locations.map((region) => {
		const regionRunStatus = canary.lastRunStatuses.find(
			(item) => item.region === region
		)?.status;

		return {
			region,
			status: {
				state: regionRunStatus?.state ?? null,
				updated: regionRunStatus?.updated
					? new Date(regionRunStatus.updated).toISOString()
					: null,
			},
		};
	});

	const statuses = canary.locations.map((region) => {
		const regionStatus = canary.statuses.find(
			(item) => item.region === region
		)?.status;

		return {
			region,
			status: {
				state: regionStatus?.state ?? null,
				updated: regionStatus?.updated
					? new Date(regionStatus.updated).toISOString()
					: null,
			},
		};
	});

	return (
		<TableRow hover>
			<TableCell>
				<Link to={`/canaries/${canary.name}`}>{canary.name}</Link>
			</TableCell>
			<TableCell>{canary.endpoint}</TableCell>
			<TableCell>
				<StatusesDropdown
					id="last-runs-dropdown"
					type="run"
					label={`${passedCount}/${canary.locations.length} Passed`}
					statuses={lastRuns}
				/>
			</TableCell>
			<TableCell>
				<StatusesDropdown
					id="statuses-dropdown"
					type="canary"
					label={`${runningCount}/${canary.locations.length} Running`}
					statuses={statuses}
				/>
			</TableCell>
			<TableCell>
				{canary.tags.map(({ key, value }) => (
					<Chip
						key={key}
						label={`${key}: ${value}`}
						variant="filled"
						sx={{ margin: 0.1 }}
					/>
				))}
			</TableCell>
			<TableCell>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<IconButton
						aria-label="start"
						sx={{
							borderRadius: 10,
							color: "success.main",
						}}
						size="small"
						onClick={startHandler}
						color="inherit"
						disabled={startDisabled}
					>
						<PlayArrowRoundedIcon
							fontSize="large"
							color={startDisabled ? "disabled" : "success"}
							sx={{ cursor: "pointer" }}
						/>
					</IconButton>
					<Divider flexItem orientation="vertical" />
					<IconButton
						aria-label="stop"
						sx={{
							borderRadius: 10,
							color: "error.main",
						}}
						size="small"
						onClick={stopHandler}
						color="inherit"
						disabled={stopDisabled}
					>
						<StopRoundedIcon
							fontSize="large"
							color={stopDisabled ? "disabled" : "error"}
							sx={{ cursor: "pointer" }}
						/>
					</IconButton>
					<Divider flexItem orientation="vertical" />
					<IconButton
						aria-label="delete"
						sx={{
							borderRadius: 10,
							color: "error.main",
						}}
						size="small"
						onClick={deleteHandler}
						color="inherit"
						disabled={deleteDisabled}
					>
						<DeleteRoundedIcon
							fontSize="large"
							color={deleteDisabled ? "disabled" : "error"}
							sx={{ cursor: "pointer" }}
						/>
					</IconButton>
				</Box>
			</TableCell>
		</TableRow>
	);
};
