import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

interface HelpDocumentDialogProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

export const HelpDocumentDialog = ({
	isOpen,
	setIsOpen,
}: HelpDocumentDialogProps) => {
	const [content, setContent] = useState("");
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-webpack-loader-syntax,import/no-unresolved
		const markdown = require("!!raw-loader!../../Tutorial.md")?.default; // MDX Context
		setContent(markdown);
	}, []);

	return (
		<BootstrapDialog
			onClose={() => setIsOpen(false)}
			aria-labelledby="customized-dialog-title"
			open={isOpen}
			fullWidth={true}
			maxWidth={"xl"}
		>
			<IconButton
				aria-label="close"
				onClick={() => setIsOpen(false)}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<Markdown>{content}</Markdown>
			</DialogContent>
		</BootstrapDialog>
	);
};
