import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { Canary } from "../../api";
import { useActionsButtonsState } from "../../hooks";

export type CanaryDetailsActionsProps = {
	canary: Canary;
	onStart: (canaryId: string) => Promise<void>;
	onStop: (canaryId: string) => Promise<void>;
	onDelete: (canaryId: string) => void;
};

export const CanaryDetailsActions = ({
	canary,
	onStart,
	onStop,
	onDelete,
}: CanaryDetailsActionsProps) => {
	const navigate = useNavigate();

	const canaryStates = canary.statuses
		.filter((s) => canary.locations.includes(s.region))
		.map((s) => s.status?.state);
	const { startDisabled, stopDisabled, deleteDisabled, disableActions } =
		useActionsButtonsState(canaryStates);

	const startHandler = async () => {
		disableActions();
		await onStart(canary.name);
	};

	const stopHandler = async () => {
		disableActions();
		await onStop(canary.name);
	};

	const deleteHandler = async () => {
		onDelete(canary.name);
	};

	const handleEditClick = () => {
		navigate(`/canaries/${canary.name}/edit`);
	};

	return (
		<Box sx={{ display: "flex", justifyContent: "center" }}>
			<IconButton
				sx={{
					borderRadius: 10,
					color: "success.main",
					width: "60px",
				}}
				size="small"
				onClick={startHandler}
				color="inherit"
				disabled={startDisabled}
			>
				<Stack direction="column" alignItems="center">
					<PlayArrowRoundedIcon
						fontSize="large"
						color={startDisabled ? "disabled" : "success"}
						sx={{ cursor: "pointer" }}
					/>
					<Typography
						color={"text.primary"}
						sx={{ fontSize: "0.75rem", fontWeight: 500, lineHeight: 1 }}
					>
						Start
					</Typography>
				</Stack>
			</IconButton>
			<IconButton
				sx={{
					borderRadius: 10,
					color: "error.main",
					width: "60px",
				}}
				size="small"
				onClick={stopHandler}
				color="inherit"
				disabled={stopDisabled}
			>
				<Stack direction="column" alignItems="center">
					<StopRoundedIcon
						fontSize="large"
						color={stopDisabled ? "disabled" : "error"}
						sx={{ cursor: "pointer" }}
					/>
					<Typography
						color={"text.primary"}
						sx={{ fontSize: "0.75rem", fontWeight: 500, lineHeight: 1 }}
					>
						Stop
					</Typography>
				</Stack>
			</IconButton>
			<IconButton
				sx={{
					borderRadius: 10,
					color: "info.main",
					width: "60px",
				}}
				size="medium"
				color="inherit"
				onClick={handleEditClick}
			>
				<Stack direction="column" alignItems="center">
					<SettingsIcon fontSize="large" sx={{ cursor: "pointer" }} />
					<Typography
						color={"text.primary"}
						sx={{ fontSize: "0.75rem", fontWeight: 500, lineHeight: 1 }}
					>
						Edit
					</Typography>
				</Stack>
			</IconButton>
			<IconButton
				sx={{
					borderRadius: 10,
					color: "info.main",
					width: "60px",
				}}
				size="medium"
				color="inherit"
				onClick={deleteHandler}
				disabled={deleteDisabled}
			>
				<Stack direction="column" alignItems="center">
					<DeleteRoundedIcon
						fontSize="large"
						color={deleteDisabled ? "disabled" : "error"}
						sx={{ cursor: "pointer" }}
					/>
					<Typography
						color={"text.primary"}
						sx={{ fontSize: "0.75rem", fontWeight: 500, lineHeight: 1 }}
					>
						Delete
					</Typography>
				</Stack>
			</IconButton>
		</Box>
	);
};
