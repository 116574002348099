import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	Chip,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import React, { ChangeEvent, useCallback, useState } from "react";

import { CanariesListFilters, Tag } from "../../../types";

export interface CanariesListFilterProps {
	onFiltersChange: (filters: CanariesListFilters) => void;
}

export const CanariesListFilter: React.FC<CanariesListFilterProps> = ({
	onFiltersChange,
}) => {
	const [searchValue, setSearchValue] = useState<string>("");
	const [tagKey, setTagKey] = useState<string>("");
	const [tagValue, setTagValue] = useState<string>("");
	const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

	const updateFilters = (newFilters: Partial<CanariesListFilters>) => {
		const updatedFilters: CanariesListFilters = {
			searchValue,
			tags: selectedTags,
			...newFilters,
		};
		onFiltersChange(updatedFilters);
	};

	const debouncedHandleSearchChange = useCallback(
		debounce((value: string) => {
			updateFilters({ searchValue: value });
		}, 800),
		[]
	);

	const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setSearchValue(newValue);
		debouncedHandleSearchChange(newValue);
	};

	const handleSearchValueClear = () => {
		setSearchValue("");
		updateFilters({ searchValue: "" });
	};

	const handleTagKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setTagKey(newValue);
	};

	const handleTagValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setTagValue(newValue);
	};

	const handleAddTagFilter = () => {
		if (tagKey && tagValue) {
			const newTag = { key: tagKey, value: tagValue };
			const updatedTags = [...selectedTags, newTag];
			setSelectedTags(updatedTags);
			updateFilters({ tags: updatedTags });
			setTagKey("");
			setTagValue("");
		}
	};

	const handleRemoveTagFilter = (key: string) => {
		const restTags = selectedTags.filter((tag) => tag.key !== key);
		setSelectedTags(restTags);
		updateFilters({ tags: restTags });
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box display="flex" flexDirection={"row"}>
					<Box>
						<Typography variant="subtitle2">Search by name:</Typography>
						<TextField
							variant="outlined"
							size="small"
							value={searchValue}
							onChange={handleSearchValueChange}
							placeholder="Search..."
							InputProps={{
								startAdornment: <SearchIcon fontSize="small" />,
								endAdornment: (
									<IconButton
										title="Clear"
										aria-label="Clear"
										size="small"
										onClick={handleSearchValueClear}
									>
										<ClearIcon fontSize="small" />
									</IconButton>
								),
							}}
							sx={{
								backgroundColor: "white",
								"& input": {
									fontSize: "0.875rem",
								},
							}}
						/>
					</Box>
					<Box ml={3}>
						<Typography variant="subtitle2">Tags:</Typography>
						<TextField
							variant="outlined"
							size="small"
							value={tagKey}
							onChange={handleTagKeyChange}
							placeholder="Tag Key"
							sx={{
								backgroundColor: "white",
								width: 120,
								fontSize: "0.875rem",
								"& input": {
									fontSize: "0.875rem",
								},
							}}
						/>
						<TextField
							variant="outlined"
							size="small"
							value={tagValue}
							onChange={handleTagValueChange}
							placeholder="Tag Value"
							sx={{
								ml: 1,
								backgroundColor: "white",
								width: 120,
								fontSize: "0.875rem",
								"& input": {
									fontSize: "0.875rem",
								},
							}}
						/>
						<IconButton
							title="Add Tag Filter"
							aria-label="Add Tag Filter"
							size="small"
							onClick={handleAddTagFilter}
						>
							<AddIcon fontSize="small" />
						</IconButton>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} py={0.5}>
				{selectedTags.map(({ key, value }) => (
					<Chip
						key={key}
						label={`${key}: ${value}`}
						onDelete={() => handleRemoveTagFilter(key)}
						color="primary"
						variant="outlined"
						sx={{
							p: 0.5,
							m: 0.5,
							backgroundColor: "white",
							"& .MuiChip-label": {
								overflow: "visible",
							},
						}}
					/>
				))}
			</Grid>
		</Grid>
	);
};
