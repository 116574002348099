import dayjs from "dayjs";

export const calcAxisTimestamps = (rangeInHours: number, intervals = 12) => {
	const endTimestamp = dayjs();

	const startTimestamp = endTimestamp.subtract(rangeInHours, "hours");

	// Calculate the tick interval based on the range
	const tickInterval = Math.ceil((rangeInHours * 60) / intervals);

	const closestMinuteBefore = endTimestamp
		.subtract(endTimestamp.minute() % tickInterval, "minutes")
		.startOf("minute");

	// Generate an array of timestamps at calculated intervals up to the closest fifteenth minute before now
	const xAxisTicks = Array.from({ length: intervals }, (_, index) =>
		closestMinuteBefore.subtract(index * tickInterval, "minutes").valueOf()
	).reverse();

	return { startTimestamp, endTimestamp, xAxisTicks };
};

export const customDotProps = (color: string) => ({
	dot: {
		opacity: 1,
		fill: color,
		stroke: color,
		strokeWidth: 1,
		r: 2,
	},
	activeDot: {
		opacity: 1,
		fill: color,
		stroke: color,
		strokeWidth: 1.1,
		r: 3,
	},
});

export const lineColors = [
	"#1f77b4",
	"#ff7f0e",
	"#2ca02c",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#e377c2",
	"#7f7f7f",
	"#bcbd22",
	"#17becf",
	"#ff00ff",
	"#808080",
	"#ffd700",
	"#00ced1",
	"#800000",
	"#000080",
	"#ff4500",
	"#ffa500",
	"#8a2be2",
];
