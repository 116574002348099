import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import {
	Canary,
	CanaryRunStates,
	CanaryStates,
	GetMetricsResponse,
} from "../../api";
import { DataDropdown } from "../DataDropdown";
import { StatusesDropdown } from "./StatusesDropdown";

export type CanaryDetailsSummaryProps = {
	canary: Canary;
	metrics: GetMetricsResponse;
};

const prepUptimeData = (data: Record<string, number>) => {
	const regionalData = Object.entries(data);
	const { totalUptime, uptimeData } = regionalData.reduce(
		(acc, [location, regionalUptime]) => {
			acc.totalUptime += regionalUptime;
			acc.uptimeData.push({
				key: location,
				value: `${regionalUptime.toFixed(2)}%`,
			});
			return acc;
		},
		{ totalUptime: 0, uptimeData: [] as { key: string; value: string }[] }
	);
	const averageUptime = (totalUptime / regionalData.length).toFixed(2);

	return { uptimeData, averageUptime };
};

const prepOutagesData = (data: Record<string, number>) => {
	const regionalData = Object.entries(data);
	const { totalOutages, outagesData } = regionalData.reduce(
		(acc, [location, regionalOutages]) => {
			acc.totalOutages += regionalOutages;
			acc.outagesData.push({ key: location, value: regionalOutages });
			return acc;
		},
		{ totalOutages: 0, outagesData: [] as { key: string; value: number }[] }
	);
	const averageOutages = (totalOutages / regionalData.length).toFixed(2);

	return { outagesData, averageOutages };
};

const prepDowntimeData = (data: Record<string, number>) => {
	const regionalData = Object.entries(data);
	const { totalDowntime, downtimeData } = regionalData.reduce(
		(acc, [location, regionalDowntime]) => {
			acc.totalDowntime += regionalDowntime;
			acc.downtimeData.push({
				key: location,
				value: `${regionalDowntime} mins`,
			});
			return acc;
		},
		{ totalDowntime: 0, downtimeData: [] as { key: string; value: string }[] }
	);
	const averageDowntime = (totalDowntime / regionalData.length).toFixed(2);

	return { downtimeData, averageDowntime };
};

export const CanaryDetailsSummary = ({
	canary,
	metrics,
}: CanaryDetailsSummaryProps) => {
	const passedCount = canary.lastRunStatuses.filter(
		(item) => item.status.state === CanaryRunStates.PASSED
	).length;

	const runningCount = canary.statuses.filter(
		(item) => item.status.state === CanaryStates.RUNNING
	).length;

	const lastRuns = canary.locations.map((region) => {
		const regionRunStatus = canary.lastRunStatuses.find(
			(item) => item.region === region
		)?.status;

		return {
			region,
			status: {
				state: regionRunStatus?.state ?? null,
				updated: regionRunStatus?.updated
					? new Date(regionRunStatus.updated).toISOString()
					: null,
			},
		};
	});

	const statuses = canary.locations.map((region) => {
		const regionStatus = canary.statuses.find(
			(item) => item.region === region
		)?.status;

		return {
			region,
			status: {
				state: regionStatus?.state ?? null,
				updated: regionStatus?.updated
					? new Date(regionStatus.updated).toISOString()
					: null,
			},
		};
	});

	const { averageUptime, uptimeData } = prepUptimeData(metrics.uptime);
	const { averageOutages, outagesData } = prepOutagesData(metrics.outages);
	const { averageDowntime, downtimeData } = prepDowntimeData(metrics.downtime);

	return (
		<TableContainer component={Paper} elevation={0}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell colSpan={5}>
							<Typography variant="h6">Summary (last 24 hours)</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									State
								</Typography>
								<StatusesDropdown
									id="statuses-dropdown"
									type="canary"
									label={`${runningCount}/${canary.locations.length} Running`}
									statuses={statuses}
								/>
							</Box>
						</TableCell>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									Latest Runs
								</Typography>
								<StatusesDropdown
									id="last-runs-dropdown"
									type="run"
									label={`${passedCount}/${canary.locations.length} Passed`}
									statuses={lastRuns}
								/>
							</Box>
						</TableCell>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									Avg. Uptime
								</Typography>
								<DataDropdown
									id="uptime-dropdown"
									label={`${averageUptime}%`}
									data={uptimeData}
								/>
							</Box>
						</TableCell>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									Avg. Outages
								</Typography>
							</Box>
							<DataDropdown
								id="downtime-dropdown"
								label={averageOutages}
								data={outagesData}
							/>
						</TableCell>
						<TableCell>
							<Box>
								<Typography variant="body2" color="GrayText">
									Avg. Downtime
								</Typography>
								<DataDropdown
									id="downtime-dropdown"
									label={`${averageDowntime} mins`}
									data={downtimeData}
								/>
							</Box>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};
