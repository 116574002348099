import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";

type TooltipPayload = { name: string; value: number; color: string };

interface MultiRegionTooltipProps {
	unit: "ms" | "percent";
	active?: boolean;
	payload?: TooltipPayload[];
	label?: string;
}

const getUnitString = (unit: "ms" | "percent") => {
	if (unit === "ms") {
		return "ms";
	}
	if (unit === "percent") {
		return "%";
	}
	return "";
};

export const MultiRegionTooltip = (props: MultiRegionTooltipProps) => {
	const { unit, active, payload, label } = props;

	if (active && payload) {
		const date = dayjs(label).format("D MMM, YYYY H:mm:ss");
		return (
			<Paper>
				<Box p={1}>
					<Typography variant="body2" fontWeight={500}>
						{date}
					</Typography>
					{payload.map((entry) => (
						<Box
							key={entry.name}
							display={"flex"}
							justifyContent={"space-between"}
							width={"100%"}
							gap={2}
						>
							<Typography key={entry.name} variant="body2" color={entry.color}>
								{entry.name}:
							</Typography>
							<Typography key={entry.value} variant="body2" color={entry.color}>
								{entry.value}
								{getUnitString(unit)}
							</Typography>
						</Box>
					))}
				</Box>
			</Paper>
		);
	}

	return null;
};
