import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";

export interface DataDropdownProps {
	id: string;
	label: string;
	data: { key: string; value: string | number }[];
}

export const DataDropdown = ({ id, label, data }: DataDropdownProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				id={`${id}-btn`}
				onClick={handleClick}
				color="inherit"
				endIcon={<ArrowDropDownIcon />}
			>
				<Typography variant="body2">{label}</Typography>
			</Button>
			<Menu
				id={`${id}-menu`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": `${id}-btn`,
					style: { padding: 0 },
				}}
			>
				{data.map((item, index) => (
					<MenuItem key={index} dense={true} divider sx={{ paddingY: 1 }}>
						<Box
							display={"flex"}
							justifyContent={"space-between"}
							width={"100%"}
							gap={2}
						>
							<span>{item.key}</span>
							<span>{item.value}</span>
						</Box>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
