export enum AwsRegionCodes {
	US_EAST_1 = "us-east-1",
	US_EAST_2 = "us-east-2",
	US_WEST_1 = "us-west-1",
	US_WEST_2 = "us-west-2",
	AP_SOUTH_1 = "ap-south-1",
	AP_NORTHEAST_1 = "ap-northeast-1",
	AP_NORTHEAST_2 = "ap-northeast-2",
	AP_NORTHEAST_3 = "ap-northeast-3",
	AP_SOUTHEAST_1 = "ap-southeast-1",
	AP_SOUTHEAST_2 = "ap-southeast-2",
	CA_CENTRAL_1 = "ca-central-1",
	EU_CENTRAL_1 = "eu-central-1",
	EU_WEST_1 = "eu-west-1",
	EU_WEST_2 = "eu-west-2",
	EU_WEST_3 = "eu-west-3",
	EU_NORTH_1 = "eu-north-1",
	SA_EAST_1 = "sa-east-1",
}

export enum AwsRegionNames {
	US_EAST_1 = "US East (N. Virginia)",
	US_EAST_2 = "US East (Ohio)",
	US_WEST_1 = "US West (N. California)",
	US_WEST_2 = "US West (Oregon)",
	AP_SOUTH_1 = "Asia Pacific (Mumbai)",
	AP_NORTHEAST_1 = "Asia Pacific (Tokyo)",
	AP_NORTHEAST_2 = "Asia Pacific (Seoul)",
	AP_NORTHEAST_3 = "Asia Pacific (Osaka)",
	AP_SOUTHEAST_1 = "Asia Pacific (Singapore)",
	AP_SOUTHEAST_2 = "Asia Pacific (Sydney)",
	CA_CENTRAL_1 = "Canada (Central)",
	EU_CENTRAL_1 = "Europe (Frankfurt)",
	EU_WEST_1 = "Europe (Ireland)",
	EU_WEST_2 = "Europe (London)",
	EU_WEST_3 = "Europe (Paris)",
	EU_NORTH_1 = "Europe (Stockholm)",
	SA_EAST_1 = "South America (Sao Paulo)",
}

export const AwsRegions = [
	{ code: AwsRegionCodes.US_EAST_1, name: AwsRegionNames.US_EAST_1 },
	{ code: AwsRegionCodes.US_EAST_2, name: AwsRegionNames.US_EAST_2 },
	{ code: AwsRegionCodes.US_WEST_1, name: AwsRegionNames.US_WEST_1 },
	{ code: AwsRegionCodes.US_WEST_2, name: AwsRegionNames.US_WEST_2 },
	{ code: AwsRegionCodes.AP_SOUTH_1, name: AwsRegionNames.AP_SOUTH_1 },
	{ code: AwsRegionCodes.AP_NORTHEAST_1, name: AwsRegionNames.AP_NORTHEAST_1 },
	{ code: AwsRegionCodes.AP_NORTHEAST_2, name: AwsRegionNames.AP_NORTHEAST_2 },
	{ code: AwsRegionCodes.AP_SOUTHEAST_1, name: AwsRegionNames.AP_SOUTHEAST_1 },
	{ code: AwsRegionCodes.AP_SOUTHEAST_2, name: AwsRegionNames.AP_SOUTHEAST_2 },
	{ code: AwsRegionCodes.CA_CENTRAL_1, name: AwsRegionNames.CA_CENTRAL_1 },
	{ code: AwsRegionCodes.EU_CENTRAL_1, name: AwsRegionNames.EU_CENTRAL_1 },
	{ code: AwsRegionCodes.EU_WEST_1, name: AwsRegionNames.EU_WEST_1 },
	{ code: AwsRegionCodes.EU_WEST_2, name: AwsRegionNames.EU_WEST_2 },
	{ code: AwsRegionCodes.EU_WEST_3, name: AwsRegionNames.EU_WEST_3 },
	{ code: AwsRegionCodes.EU_NORTH_1, name: AwsRegionNames.EU_NORTH_1 },
	{ code: AwsRegionCodes.SA_EAST_1, name: AwsRegionNames.SA_EAST_1 },
];
