import {
	Box,
	Table,
	TableBody,
	TableContainer,
	TableFooter,
	TablePagination,
	TableRow,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CanaryListItem } from "../../../api";
import { SortableHeaders } from "../../../constants";
import { CanariesListFilters, SortingOrder } from "../../../types";
import { getComparator } from "../../../utils";
import { CanariesListFilter } from "./CanariesListFilter";
import { CanariesTableHeader } from "./CanariesTableHeader";
import { CanariesTableRow } from "./CanariesTableRow";
import { compareCanaries, filterCanaries } from "./canariesListUtils";

export type CanariesTableProps = {
	canaries: CanaryListItem[];
	onStart: (canaryId: string) => Promise<void>;
	onStop: (canaryId: string) => Promise<void>;
	onDelete: (canaryId: string) => void;
};

export const CanariesTable = ({
	canaries,
	onStart,
	onStop,
	onDelete,
}: CanariesTableProps) => {
	const [order, setOrder] = useState<SortingOrder>("asc");
	const [orderBy, setOrderBy] = useState<SortableHeaders>(SortableHeaders.NAME);
	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState<CanariesListFilters>({
		searchValue: "",
		tags: [],
	});
	const rowsPerPage = 20;

	useEffect(() => {
		if (canaries.length <= rowsPerPage) {
			setPage(0);
		}
	}, [canaries]);

	const itemsCount = canaries.length;

	const handleChangePage = async (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	const createSortHandler = (property: SortableHeaders) => () => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const comparator = useCallback(getComparator(order), [order]);

	const visibleRows = useMemo(
		() =>
			filterCanaries(canaries, filters)
				.sort((a, b) => compareCanaries(a, b, orderBy, comparator))
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[canaries, filters, order, orderBy, page, rowsPerPage]
	);

	return (
		<Box>
			<CanariesListFilter onFiltersChange={setFilters} />
			<TableContainer>
				<Table aria-label="collapsible table">
					<CanariesTableHeader
						order={order}
						orderBy={orderBy}
						createSortHandler={createSortHandler}
					/>
					<TableBody>
						{visibleRows.map((c) => (
							<CanariesTableRow
								key={c.name}
								canary={c}
								onStart={onStart}
								onStop={onStop}
								onDelete={onDelete}
							/>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={6}
								count={itemsCount}
								rowsPerPage={rowsPerPage}
								rowsPerPageOptions={[]}
								page={page}
								onPageChange={handleChangePage}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
};
