import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { loginRequest, useAuth } from "@unified-trials/arcane-sso-react";
import { AppRoutes, Layout, Spinner } from "@unified-trials/arcane-ui-tool";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { HelpDocumentButton } from "../components/HelpDocumentButton";
import { navRoutes, privateRoutes, publicRoutes } from "./routes";

const LoadingSpinner = () => <Spinner fullscreen />;

type PrivateRoutesProps = {
	applicationConfigString: string;
};
const PrivateRoutes = ({ applicationConfigString }: PrivateRoutesProps) => {
	const { instance, inProgress } = useMsal();
	const { logout, username } = useAuth();

	const activeAccount = instance.getActiveAccount();

	const authRequest = {
		...loginRequest,
		...(activeAccount && { account: activeAccount }),
		redirectUri: process.env.REACT_APP_REDIRECT_URL,
	};

	const handleLogout = (e: React.MouseEvent) => {
		e.preventDefault();
		logout();
	};

	if (inProgress === InteractionStatus.Startup) {
		return <Spinner fullscreen />;
	}

	const appRoutes = AppRoutes(applicationConfigString, true);

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest}
			loadingComponent={LoadingSpinner}
		>
			<Layout
				handleLogout={handleLogout}
				navRoutes={navRoutes}
				appName={"Health Probes"}
				isSidebar={true}
				platformName={"Platform Tools"}
				appRoutes={appRoutes}
				username={username}
				helpComponent={<HelpDocumentButton key={"help"} />}
			>
				<Outlet />
			</Layout>
		</MsalAuthenticationTemplate>
	);
};

type RouterProps = {
	applicationConfigString: string;
};
export const Router = ({ applicationConfigString }: RouterProps) => (
	<Routes>
		{publicRoutes.map((r) => (
			<Route key={r.path} path={r.path} element={r.element} />
		))}

		<Route
			element={
				<PrivateRoutes applicationConfigString={applicationConfigString} />
			}
		>
			{privateRoutes.map((r) => (
				<Route key={r.path} path={r.path} element={r.element} />
			))}
		</Route>

		<Route path="*" element={<Navigate to="/" replace />} />
	</Routes>
);
