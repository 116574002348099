import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Checkbox,
	Container,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { BackButton, Spinner } from "@unified-trials/arcane-ui-tool";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { Canary, UpdateCanaryRequest } from "../../api";
import { AwsRegionCodes, AwsRegions } from "../../constants";
import { useApi } from "../../hooks";
import { getRegionName, showNotification } from "../../utils";

const CanarySchema = z.object({
	name: z.string(),
	pingInterval: z.coerce.number().positive(),
	locations: z
		.array(z.nativeEnum(AwsRegionCodes))
		.min(1, "At least one region is required"),
	tags: z.array(z.object({ key: z.string().min(1), value: z.string().min(1) })),
	tlsWarningThreshold: z.coerce.number().positive().min(1),
	tlsErrorThreshold: z.coerce.number(),
});

type FormValues = z.infer<typeof CanarySchema>;

type Props = {
	canary?: Canary;
};

export const EditCanary: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();
	const { name } = useParams<"name">();
	const [canary, setCanary] = useState<Canary>();
	const { getCanary, updateCanary, isLoading } = useApi();
	const {
		handleSubmit,
		control,
		register,
		reset,
		formState: { errors },
		watch,
	} = useForm<FormValues>({
		resolver: zodResolver(CanarySchema),
		defaultValues: {
			name: canary?.name ?? "",
			pingInterval: 0,
			locations: canary?.locations ?? [],
			tags: canary?.tags ?? [],
			tlsWarningThreshold: canary?.alertConfig.tlsWarningThreshold ?? 0,
			tlsErrorThreshold: canary?.alertConfig.tlsErrorThreshold ?? 0,
		},
	});

	const {
		fields: tagFields,
		append: appendTag,
		remove: removeTag,
	} = useFieldArray({
		control,
		name: "tags",
	});

	const addTag = () => {
		appendTag({
			key: "",
			value: "",
		});
	};

	useEffect(() => {
		if (!name) {
			navigate("/404");
			return;
		}

		const fetchData = async () => {
			const { data } = await getCanary(name);
			if (data) {
				setCanary(data);
				reset({
					name: data.name,
					pingInterval: data.pingInterval,
					locations: data.locations,
					tags: data.tags,
					...data.alertConfig,
				});
			}
		};
		if (props.canary) {
			setCanary(props.canary);
			reset({
				name: props.canary.name,
				pingInterval: props.canary.pingInterval,
				locations: props.canary.locations,
				tags: props.canary.tags,
				...props.canary.alertConfig,
			});
		} else {
			fetchData();
		}
	}, []);

	const handleFormSubmit = async (canaryId: string, data: FormValues) => {
		const requestData: UpdateCanaryRequest = {
			pingInterval: data.pingInterval,
			locations: data.locations,
			tags: data.tags,
			alertConfig: {
				tlsWarningThreshold: data.tlsWarningThreshold,
				tlsErrorThreshold: data.tlsErrorThreshold,
			},
		};
		const { error } = await updateCanary(canaryId, requestData);
		if (!error) {
			showNotification("success", "Successfully updated configuration!");
			navigate(`/canaries/${canaryId}`);
		}
	};

	return (
		<Container sx={{ marginBottom: 2 }}>
			{isLoading && <Spinner fullscreen opacity={0.5} />}
			<Stack direction="row" spacing={2} my={1} alignItems="center">
				<BackButton
					href={canary?.name ? `/canaries/${canary?.name}` : "/canaries"}
				/>
				<Typography variant="h4" my={2}>
					Edit {canary?.name}
				</Typography>
			</Stack>
			{!!canary && (
				<form
					onSubmit={handleSubmit((data) => handleFormSubmit(canary.name, data))}
				>
					<Paper sx={{ p: 2 }} elevation={0}>
						<Stack direction="column" spacing={2} mb={1}>
							<TextField
								value={canary.name}
								disabled
								size="small"
								sx={{ maxWidth: "500px" }}
								label="Name"
							/>
							<TextField
								value={canary.endpoint}
								disabled
								size="small"
								sx={{ maxWidth: "500px" }}
								label="Endpoint"
							/>
							<TextField
								{...register("pingInterval")}
								size="small"
								sx={{ maxWidth: "500px" }}
								inputProps={{ min: 1, "data-testid": "ping-interval-input" }}
								type="number"
								label="Ping Interval"
								error={!!errors.pingInterval}
								helperText={errors.pingInterval?.message}
							/>
							<FormControl
								sx={{ maxWidth: "500px" }}
								error={!!errors.locations}
							>
								<FormLabel>Locations (AWS regions)</FormLabel>
								<Select
									{...register("locations")}
									multiple
									size="small"
									value={watch("locations")}
									renderValue={(selected: AwsRegionCodes[]) =>
										selected.length > 3
											? `${selected.length} locations selected`
											: selected.map((s) => getRegionName(s)).join(", ")
									}
									error={!!errors.locations}
								>
									{AwsRegions.map((r) => (
										<MenuItem key={r.code} value={r.code}>
											<Checkbox checked={watch("locations").includes(r.code)} />
											<ListItemText primary={r.name} secondary={r.code} />
										</MenuItem>
									))}
								</Select>
								{errors.locations && (
									<FormHelperText>{errors.locations.message}</FormHelperText>
								)}
							</FormControl>
						</Stack>
					</Paper>

					<Paper sx={{ p: 2, my: 2 }} elevation={0}>
						<Typography variant="subtitle1" gutterBottom>
							Tags
						</Typography>
						{tagFields.map((field, index) => (
							<Stack
								key={field.id}
								direction="row"
								spacing={2}
								sx={{ mb: 2, width: "100%" }}
							>
								<TextField
									{...register(`tags.${index}.key`)}
									size="small"
									sx={{ maxWidth: "500px" }}
									fullWidth
									label="Tag Key"
									error={!!errors.tags?.[index]?.key}
									helperText={errors.tags?.[index]?.key?.message}
								/>
								<TextField
									{...register(`tags.${index}.value`)}
									size="small"
									sx={{ maxWidth: "500px" }}
									fullWidth
									label="Tag Value"
									error={!!errors.tags?.[index]?.value}
									helperText={errors.tags?.[index]?.value?.message}
								/>
								<IconButton onClick={() => removeTag(index)} color="primary">
									<DeleteIcon sx={{ cursor: "pointer" }} />
								</IconButton>
							</Stack>
						))}
						<Stack direction="row" alignItems="center" spacing={2} my={1}>
							<Button
								size="small"
								variant="outlined"
								onClick={addTag}
								color="primary"
							>
								Add new tag
							</Button>
						</Stack>
					</Paper>

					<Accordion elevation={0}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 2 }}>
							Alarms configuration
						</AccordionSummary>
						<AccordionDetails>
							<Stack direction="row" spacing={2} sx={{ width: "100%" }}>
								<TextField
									{...register("tlsWarningThreshold")}
									size="small"
									type="number"
									sx={{ maxWidth: "500px" }}
									fullWidth
									inputProps={{
										min: 1,
										"data-testid": "tls-warning-threshold-input",
									}}
									label="Warning Threshold"
									error={!!errors.tlsWarningThreshold}
									helperText={errors.tlsWarningThreshold?.message}
								/>
							</Stack>
						</AccordionDetails>
					</Accordion>

					<Stack direction="row" spacing={2} my={1} justifyContent={"center"}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ color: "white " }}
						>
							Submit
						</Button>
					</Stack>
				</form>
			)}
		</Container>
	);
};
