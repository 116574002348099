import { Container, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BackButton, Spinner } from "@unified-trials/arcane-ui-tool";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
	AlarmConfiguration,
	Canary,
	CanaryHistory,
	GetMetricsResponse,
} from "../../api";
import { DeletionConfirmationModal } from "../../components/DeletionConfirmationModal";
import { CanaryConfiguration } from "../../components/canaries/CanaryConfiguration";
import { CanaryDetailsSummary } from "../../components/canaries/CanaryDetailsSummary";
import { CanaryDetailsActions } from "../../components/canaries/DetailsActions";
import { CanaryAvailabilityChart } from "../../components/canaries/charts/AvailabilityChart";
import { DurationChart } from "../../components/canaries/charts/DurationChart";
import { MonitoringCharts } from "../../components/canaries/charts/MonitoringCharts";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`details-tabpanel-${index}`}
			aria-labelledby={`details-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `details-tab-${index}`,
		"aria-controls": `details-tabpanel-${index}`,
	};
};

export const CanaryDetails = () => {
	const navigate = useNavigate();
	const { name } = useParams<"name">();
	const {
		isLoading,
		startCanary,
		pauseCanary,
		getCanary,
		getCanaryMetrics,
		getCanaryHistory,
		deleteCanary,
		listAlarmsByName,
	} = useApi();
	const [tabValue, setTabValue] = useState(0);
	const [canary, setCanary] = useState<Canary>();
	const [canaryHistory, setCanaryHistory] = useState<CanaryHistory>();
	const [canaryMetrics, setCanaryMetrics] = useState<GetMetricsResponse>();
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
	const [alarmConfigurations, setAlarmConfigurations] = useState<
		AlarmConfiguration[]
	>([]);
	const [alarmConfigrationsNextToken, setAlarmConfigrationsNextToken] =
		useState<string | null>(null);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const fetchData = async (canaryName: string) => {
		const canaryResultPromise = getCanary(canaryName).then((response) => {
			if (response.data) setCanary(response.data);
		});
		const metricsResultPromise = getCanaryMetrics(canaryName).then(
			(response) => {
				if (response.data) setCanaryMetrics(response.data);
			}
		);
		const historyResultPromise = getCanaryHistory(canaryName).then(
			(response) => {
				if (response.data) setCanaryHistory(response.data);
			}
		);
		const metricAlarmResultPromise = listAlarmsByName(canaryName).then(
			(response) => {
				if (response.data) {
					setAlarmConfigurations(response.data.data);
					setAlarmConfigrationsNextToken(response?.data.nextToken || null);
				}
			}
		);

		await Promise.all([
			canaryResultPromise,
			metricsResultPromise,
			historyResultPromise,
			metricAlarmResultPromise,
		]);
	};

	useEffect(() => {
		if (!name) {
			navigate("/404");
			return;
		}

		fetchData(name);
	}, []);

	const handleStart = async (canaryName: string) => {
		const { error } = await startCanary(canaryName);
		if (!error) {
			await fetchData(canaryName);
			showNotification("success", `Successfully began starting ${canaryName}`);
		}
	};

	const handleStop = async (canaryName: string) => {
		const { error } = await pauseCanary(canaryName);
		if (!error) {
			await fetchData(canaryName);
			showNotification("success", `Successfully began stopping ${canaryName}`);
		}
	};

	const handleDelete = async (canaryName: string) => {
		setIsConfirmationOpen(false);
		const { error } = await deleteCanary(canaryName);
		if (!error) {
			showNotification(
				"success",
				`Successfully started deletion of ${canaryName}`
			);
			navigate("/canaries");
		}
	};

	const handleDeleteConfirmation = () => {
		setIsConfirmationOpen(true);
	};

	const handleGetNextAlarmConfigurations = async () => {
		if (name) {
			const { data: alarmConfigurationsData } = await listAlarmsByName(
				name,
				alarmConfigrationsNextToken
			);
			if (
				alarmConfigurationsData?.data.length &&
				alarmConfigurationsData?.data.length > 0
			) {
				setAlarmConfigurations([
					...alarmConfigurations,
					...alarmConfigurationsData.data,
				]);
				setAlarmConfigrationsNextToken(alarmConfigurationsData.nextToken);
			}
		}
	};

	const successChartData = Object.entries(
		canaryMetrics?.successPercent ?? {}
	).map(([location, dataPoints]) => ({
		location,
		data: dataPoints,
	}));

	const durationChartData = Object.entries(canaryMetrics?.duration ?? {}).map(
		([location, dataPoints]) => ({
			location,
			data: dataPoints,
		})
	);

	return (
		<Container>
			{isLoading && <Spinner fullscreen opacity={0.5} />}
			<Stack direction="row" spacing={2} mb={1} alignItems="center">
				<BackButton href="/canaries" />
				{!!canary && (
					<>
						<Typography variant="h4">{canary?.name}</Typography>
						<Box flexGrow={1} />
						<CanaryDetailsActions
							canary={canary}
							onStart={handleStart}
							onStop={handleStop}
							onDelete={handleDeleteConfirmation}
						/>
					</>
				)}
			</Stack>
			{canary && canaryMetrics && (
				<CanaryDetailsSummary canary={canary} metrics={canaryMetrics} />
			)}
			{canary && canaryHistory && canaryMetrics && alarmConfigurations && (
				<>
					<Box mt={2} p={0} sx={{ width: "100%" }}>
						<Box p={0} sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs value={tabValue} onChange={handleChange}>
								<Tab label="Availability" {...a11yProps(0)} />
								<Tab label="Monitoring" {...a11yProps(1)} />
								<Tab label="Configuration" {...a11yProps(2)} />
							</Tabs>
						</Box>
						<CustomTabPanel value={tabValue} index={0}>
							<Paper sx={{ backgroundColor: "white", p: 1 }} elevation={0}>
								<Typography variant="h6" ml={2} my={1}>
									Availability
								</Typography>
								{!!canaryMetrics && (
									<CanaryAvailabilityChart data={successChartData} />
								)}
							</Paper>
							<Paper sx={{ backgroundColor: "white", p: 1 }} elevation={0}>
								<Typography variant="h6" ml={2} my={1}>
									Response Time (in milliseconds)
								</Typography>
								{!!canaryMetrics && <DurationChart data={durationChartData} />}
							</Paper>
						</CustomTabPanel>
						<CustomTabPanel value={tabValue} index={1}>
							<MonitoringCharts canaryHistory={canaryHistory} />
						</CustomTabPanel>
						<CustomTabPanel value={tabValue} index={2}>
							<CanaryConfiguration
								moreAlarmConfigurationsAvailable={!!alarmConfigrationsNextToken}
								handleGetNextAlarmConfigurations={
									handleGetNextAlarmConfigurations
								}
								canary={canary}
								alarmConfigurations={alarmConfigurations}
							/>
						</CustomTabPanel>
					</Box>
					<DeletionConfirmationModal
						isOpen={isConfirmationOpen}
						resource={canary.name}
						setIsOpen={setIsConfirmationOpen}
						handleDelete={() => handleDelete(canary.name)}
					/>
				</>
			)}
		</Container>
	);
};
